.article-row {
    align-items: center;
    background: rgb(85, 85, 85);
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 32px;
    margin-bottom: 8px;
    overflow: hidden;

    &.pinned {
        position: fixed;
        top: 72px;
        left: 1022px;
        z-index: 9999;
        box-shadow: 0 20px 40px 10px rgba(0,0,0,1);
        width: 850px;
    }
}
.rolling-list {
    padding-top: 16px;
    overflow: scroll;
    // white-space: nowrap;
    width: 850px;
    height: 100vh;
    z-index: 3;
}

.thumbnail {
    width: 88px;
    height: 88px;
    background: rgb(57, 57, 57);
}

.article-info {
    flex: auto;
    padding: 8px 16px;
}

.stars-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    height: 5px;
    padding: 1px;
    // background: rgb(0, 0, 0);
}

.engagement {
    display: block;
    height: 3px;
    width: 20%;
    background: rgb(10, 255, 157);
    margin-right: 1px;
    border-radius: 3px;
}

.acquisition {
    display: block;
    height: 3px;
    width: 50%;
    background: rgb(255, 132, 10);
    border-radius: 3px;
}

.retention {
    display: block;
    height: 3px;
    width: 30%;
    background: rgb(149, 10, 255);
    border-radius: 3px;
    margin-left: 1px;
}

.headline {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;
}

.total {
    font-size: 28px;
    font-weight: 800;
    padding-right: 16px;
    justify-self: end;
    width: 80px;
    text-align: right;
}

.tag {
    font-size: 16px;
    font-weight: 700;
    width: 120px;
}