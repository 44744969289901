.metric-total {
    font-size: 64px;
    color: white;
    font-weight: bolder;
    // border-bottom: 1px solid white;
    // padding-bottom: 16px;
    margin-bottom: 16px;
    padding-top: 12px
}

.metric-title {
    font-size: 28px;
    color: white;
    margin-bottom: 40px;
} 