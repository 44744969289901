.ScreenHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #33383a;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  z-index: 999;
  transition: all 0.3s ease-in-out;

  &.hidden {
    top: -100px;
  }

  h1 {
    margin: 0;
    padding: 16px 32px;
    font-weight: bolder;
    font-size: 22px;
    color: #12ebf2;
  }

  span {
    font-weight: 700;
    color: white;
    opacity: .75;
    letter-spacing: 0.15em;
    margin-left: 4px;
  }

  .version {
    color: #0dbfc5;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    padding: 16px 32px;
  }
}