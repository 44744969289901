.title-row {
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 800;
    height: 56px;
    justify-content: flex-start;
    padding-left: 32px;
}

.title-one-col {
    width: 440px;
    padding-right: 80px;

   &.end {
        width: 800px;
        padding-right: 0
    }
}


.two-col {
    // border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    height: 1000px;
    width: 1400px;
  }
  

.column-subtitle {
    position: absolute;
    top: 72px;
    font-size: 14px;
    font-weight: 400;

    &.warning {
        display: flex;
        background: yellow;
        border-radius: 20px;
        color: black;
        font-weight: 700;
        font-size: 18px;
        padding: 2px 12px;
        top: 44px;
    }

    &.success {
        display: flex;
        background: rgb(74, 255, 74);
        border-radius: 20px;
        color: black;
        font-weight: 700;
        font-size: 18px;
        padding: 2px 12px;
        top: 44px;
    }
}

.column-subtitle span {
    opacity: 0.5;
    padding: 0 4px;
}