.dropdown {
    position: relative;
  }

  .dropdown-menu {
    background-color: #292b2d;
    border-radius: 12px;
    box-shadow: 0 10px 32px 8px rgba(22, 23, 23, 0.696);
    position: absolute;
    top: 100%;
    left: -90px;
    width: 400px;
    z-index: 999;
    overflow: hidden;
  }

  .dropdown-title {
    background-color: #353d41;
    padding: 8px 16px;
    text-transform: uppercase;
    color: #ffffff8a;
    font-weight: 600;
    font-size: 12px;
  }
  
  .dropdown-button {
    align-items: center;
    background-color: #ffffff20;
    border-radius: 8px;
    border: 1px clear;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 22px 4px;
    color: #ffffffcd;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 16px;
    justify-content: space-between;
    padding: 4px 14px;
  }

  .dropdown-button:hover {
    background-color: #ffffff40;
  }

  .button-icon-wrapper {
    align-items: center;
    background: rgb(22, 24, 25);
    /* background: linear-gradient(
      to top, rgba(255, 255, 255, 0.1) 20%,
       rgba(255, 255, 255, 0.05) 95%); */
    border-radius: 4px;
    border-top: 2px solid rgba(255,255,255,0.1);
    color: #12ebf2;
    display: flex;
    min-height: 32px;
    justify-content: center;
    min-width: 58px !important;
    margin-right: 16px;
  }
  
  .button-icon-wrapper svg {
    height: 18px;
    width: 18px;
    position: relative;
    top: 2px;
    fill: #9ddcdf !important;
    color: #99c3c4 !important;
  }

  .button-title {
    font-weight: 500;
    color: #ffffffc0;
    margin-bottom: 2px;
    padding-top: 6px;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .button-subtitle {
    font-weight: 500;
    color: #ffffff6d;
    opacity: 0.8;
    font-size: 14px;
    padding-bottom: 6px;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-option {
    align-items: center;
    background-color: rgba(255,255,255,0.01);
    border: none;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    padding: 12px 20px;
    position: relative;
    text-align: left;
    width: 100%;
  }

  .dropdown-option.active {
    background-color: rgba(255, 255, 255, 0.141) !important;
  }

  .dropdown-option:hover {
    background-color: rgba(255,255,255,0.05);
  }
  

  
  .dropdown-overlay {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;
  }