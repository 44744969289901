.globe-wrapper {
    display: flex;
    max-width: 1220px;
    height: 2080px !important;
    position: fixed;
    top: -280px;
    right: 120px;
}

body {
    background: rgb(0, 0, 0);
}

.fixed-one-col {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 100;
    top: 64px;
    left: 32px;
}

.fixed-col-two {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 100;
    top: 88px;
    left: 488px;
}