.title-row {
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 800;
    height: 56px;
    justify-content: flex-start;
    padding-left: 32px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.title-one-col {
    width: 440px;
    padding-right: 80px;

   &.end {
        width: 800px;
        padding-right: 0
    }
}

.column-subtitle {
    position: absolute;
    top: 72px;
    font-size: 14px;
    font-weight: 400;
}

.column-subtitle span {
    opacity: 0.5;
    padding: 0 4px;
}