.countryCard--wrapper {
    background: rgba(255,255,255,0.05);
    backdrop-filter: blur(30px) saturate(180%);
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    padding: 32px 64px;
    z-index: 1000;
    margin-bottom: 32px;
    border-radius: 30px;
}

.countryName {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 16px;
}

.card-total {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 16px;

    & span {
        font-weight: 600;
        opacity: 0.5;
    }
}